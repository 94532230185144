<template>
  <SignInLayout :subTitle="'QR-code'">
    <div class="request-reset-password">
      <template>
        <form novalidate v-on:submit.prevent="submit">
          <v-text-field
            v-model="code"
            :counter="6"
            :fullWidth="true"
            :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
            :rules="codeRules"
            outlined
            placeholder="Code Number"
            required
            @input="onInputChange($event)"
          />
          <PrimaryButton
            :disabled="isButtonDisabled"
            :loading="isLoading"
            class="request-reset-password__submit-btn"
            size="x-large"
            text="Send Code"
            type="submit"
          />
        </form>
      </template>
    </div>
  </SignInLayout>
</template>

<script>
import { mapActions, mapState } from "pinia";

import SignInLayout from "@/components/SignInLayout";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { useAppStore } from "@/pinia-store/app";
import { useAuthStore } from "@/pinia-store/auth";
import { useTwoFactorStore } from "@/pinia-store/twoFactor";

export default {
  name: "TwoFactorAuthentication",
  components: {
    SignInLayout,
    PrimaryButton,
  },
  data() {
    return {
      isButtonDisabled: true,
      isLoading: false,
      isCompleted: false,
      code: "",
      codeRules: [
        (v) => !!v || "Completely input 6 number",
        (v) => (v.length <= 6 && Number(v)) || "Code must be 6 numbers",
      ],
    };
  },
  computed: {
    ...mapState(useAppStore, ["brandName"]),
    ...mapState(useTwoFactorStore, ["getSucces"]),
    ...mapState(useAuthStore, ["role"]),
  },
  methods: {
    ...mapActions(useTwoFactorStore, ["sendVerifynumbers"]),
    ...mapActions(useAuthStore, ["setToken"]),

    async submit() {
      await this.sendVerifynumbers({
        twoFactorAuthenticationCode: this.code,
      });
      let token = await this.getSucces;
      await this.setToken(token.authorized.authorized.idToken);
      if (token.authorized.customToken) {
        localStorage.isOk = true;
        await this.$router.push(`/${this.role}/appointments`);
      }
    },
    onInputChange(e) {
      this.isButtonDisabled = !(Number(e) && e.length === 6);
    },
  },
};
</script>

<style lang="scss">
.request-reset-password {
  &__submit-btn {
    text-transform: none;

    .v-btn__content {
      text-transform: none;
    }
  }

  text-align: center;
  width: 440px;
  margin: 10px auto;

  @media (max-width: 767.98px) {
    width: auto;
    margin: 10px 20px;
  }

  &_link {
    cursor: pointer;
    display: inline-block;
    color: $primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
</style>
